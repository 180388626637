import React,{ useState, useContext } from "react";
import {Link, useNavigate } from "react-router-dom";
import { SketchPicker } from "react-color";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {firebaseConfig}  from "./App";
import {ProfileContext, AuthContext} from "./App";
import  firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import 'firebase/compat/auth';
import Navigation from "./Navigation";


  export default function Apply() {


 const [nameerror, setNameerror] = useState("");
 const [name, setName] = useState("");
 const [city, setCity] = useState("");
 const [state, setState] = useState("");
 const [phonenumber, setPhoneNumber] = useState("");
const [agree, setAgree] = useState("");
const [business, setBusiness] = useState("");
const [personal, setPersonal] = useState("");
const [domain, setDomain] = useState("");
const [website, setWebsite] = useState("");
    const [color1, setColor1] = useState('#000000');
    const [color2, setColor2] = useState('');
    const [color3, setColor3] = useState('');
    const [describe, setDescribe] = useState("");
    const [describe2, setDescribe2] = useState("");
    const [pagenumbers, setPagenumbers] = useState("");
    const [listings, setListings] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const db = firebaseConfig.firestore();

    const {authStatus}  = useContext(AuthContext);
    const {profileOpen} = useContext(ProfileContext);





    if (profileOpen){
      return (window.location="/profile")}

    

      if (!authStatus){
        return (window.location="/signup")}


      

    function validation () {

   if (!name) {
    setNameerror(<p style={{color:"#1eee60"}}> <i style={{color:"#1eee60", fontSize:"14px"}} className="bi bi-exclamation-triangle"></i> Name required!</p> )
        } else {
 
          setNameerror("");
        }

      }


    function addUsers() {
  

        db.collection("currentUser").doc(firebase.auth().currentUser.uid+1026).set({
          name: name,
          city: city,
          state: state,
          phonenumber: phonenumber,
          business: business,
          personal: personal,
          domain: domain,
          color2: color2,
          color3: color3,
          describe: describe,
          describe2: describe2,
          listings: listings,
          pagenumbers: pagenumbers,
          agree: agree,
          progress: "not started",
          date: new Date(),
          website: website,
          url: ""
    
      }, { merge: true });
        

        setName("");
        setCity("");
        setState("");
        setPhoneNumber("");
        setBusiness("");
        setPersonal("");
        setDomain("");
        setColor2("");
        setColor3("");
        setDescribe("");
        setDescribe2("");
        setListings("");
        setPagenumbers("");
        setAgree("");
        setWebsite("");
      
     navigate('/profile');
      window.scrollTo(0, 0);
      }
        
  
 

  return (
    <>
  
    <div className="intro2">


<Modal
    show={show}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header style={{backgroundColor:"lightgray"}} closeButton> <h4>Terms of Service</h4> </Modal.Header>
    <Modal.Body style={{backgroundColor:"lightgray"}} >
  
Terms<br></br>
The “entity” is Night Tremors Designs. “Services” include website design, website content updates, and website hosting. The “client” is the individual/company requesting services from the entity.
<br></br><br></br>
Website Design Plans<br></br>
All clients receiving services from the entity must agree to pay the amounts requested for the payment plan they choose that are listed on the entity’s website at the time the payment is made. If they do not agree with the payment plan, they must not proceed with the payment for services. Payments for services are fixed fees that must be paid before services are rendered. The level of customization to the website(s) provided to the client is to be determined by the entity while negotiations on the customization of the website(s) may be made between the entity and the client throughout the process of the product development. 
<br></br><br></br>
Refunds<br></br>
Refunds for website design services provided will not be released if the end product that was approved by the client has already been published. A full refund cannot be granted if work on the project had already started at the time of the refund request. Partial refunds of up to 75% of the payment plan that was purchased can be released if the client is unsatisfied with the website design sample previewed before the finished project is published online. Refunds for maintenance fees may be provided if the client and the entity agree that maintenance and hosting will be completed by the client on the month that maintenance fees were paid before services were rendered for the month that the responsibility of maintenance was transferred from the entity to the client. It is the client’s responsibility to promptly send an email to the entity for a refund request detailing the reasoning for the refund request before the work has been published for a website design refund or before maintenance to the website is provided for a maintenance fee refund. The client must allow the entity up to 5 days to process the refund request.
<br></br><br></br>
Product Ownership<br></br>
While the entity is responsible for creating the websites offered and providing hosting for their client’s websites, full ownership of the website is given to the client. In the event that the client requests to discontinue their relationship with the entity, the entity will release the website data to the client or migrate the data to the client’s new website host and discontinue accepting payments for monthly maintenance fees within 14 days of the request.
<br></br><br></br>
Security<br></br>
The security of the website is provided by the web hosting service while payment processing security is provided by the company of the payment processor. In the event that a security issue on the client’s website arises, the client should contact the entity so that the appropriate parties can be contacted in order to address the security concerns.
<br></br><br></br>
Refusal of Service<br></br>
We have the right to refuse service to potential clients for a variety of reasons including but not limited to inappropriate and/or abusive behavior displayed by the client, the client requests websites that include content prohibited by the hosting company such as explicit content and websites created to generate income through gambling, and clients that demand any requests that cannot be fulfilled by the entity. In such cases, refunds will be issued when appropriate.

      <br></br><br></br>     
      <Button variant="secondary" onClick={handleClose}>
        I Agree
      </Button>
    </Modal.Body>
  </Modal>

<br></br>
<div >

 <br></br>

<div className='box1'>
   <form onSubmit={addUsers} >   
  <div >

      <h5>Contact Information</h5><br/>
      <div className="form-group col-md-4">
    <label htmlFor="name">Name</label>
 <input type="text"  className="form-control"  value={name} onChange={e => setName(e.target.value)} required />
 <div id="password">{nameerror}</div>
  </div>
 
    <div className="form-group col-md-4">
      <label htmlFor="City">City</label>
      <input type="text" className="form-control"  value={city} onChange={e => setCity(e.target.value)}/>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="State">State</label>
      <select  id="State" className="form-control" value={state} onChange={e => setState(e.currentTarget.value) }>
        <option value>Choose ...</option>
            <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="Arizona">Arizona</option>
            <option value="Arkansas">Arkansas</option>
            <option value="California">California</option>
            <option value="Colorado">Colorado</option>
            <option value="Connecticut">Connecticut</option>
            <option value="Delaware">Delaware</option>
            <option value="Florida">Florida</option>
            <option value="Georgia">Georgia</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Idaho">Idaho</option>
            <option value="Illinois">Illinois</option>
            <option value="Indiana">Indiana</option>
            <option value="Iowa">Iowa</option>
            <option value="Kansas">Kansas</option>
            <option value="Kentucky">Kentucky</option>
            <option value="Louisiana">Louisiana</option>
            <option value="Maine">Maine</option>
            <option value="Maryland">Maryland</option>
            <option value="Massachusetts">Massachusetts</option>
            <option value="Michigan">Michigan</option>
            <option value="Minnesota">Minnesota</option>
            <option value="Mississippi">Mississippi</option>
            <option value="Missouri">Missouri</option>
            <option value="Montana">Montana</option>
            <option value="Nebraska">Nebraska</option>
            <option value="Nevada">Nevada</option>
            <option value="New Hampshire">New Hampshire</option>
            <option value="New Jersey">New Jersey</option>
            <option value="New Mexico">New Mexico</option>
            <option value="New York">New York</option>
            <option value="North Carolina">North Carolina</option>
            <option value="North Dakota">North Dakota</option>
            <option value="Ohio">Ohio</option>
            <option value="Oklahoma">Oklahoma</option>
            <option value="Oregon">Oregon</option>
            <option value="Pennsylvania">Pennsylvania</option>
            <option value="Rhode Island">Rhode Island</option>
            <option value="South Carolina">South Carolina</option>
            <option value="South Dakota">South Dakota</option>
            <option value="Tennessee">Tennessee</option>
            <option value="Texas">Texas</option>
            <option value="Utah">Utah</option>
            <option value="Vermont">Vermont</option>
            <option value="Virginia">Virginia</option>
            <option value="Washington">Washington</option>
            <option value="West Virginia">West Virginia</option>
            <option value="Wisconsin">Wisconsin</option>
            <option value="Wyoming">Wyoming</option>
            <option value="Washington D.C.">Washington D.C.</option>
      </select>
    </div>
  
    
        <div className="form-group col-md-4" >
      <label htmlFor="Phone"  >Phone Number</label>
      <PhoneInput 
      type="text"
      placeholder=""
      value={phonenumber}
      onChange={setPhoneNumber}/>
    
    </div>

    <br></br><br></br>
    <h5>Work Requirements</h5><br/>
    <div class="form-group col-md-4">
    <label htmlFor="business">Type of Business</label>
 <input type="text"  className="form-control"  value={business} onChange={e => setBusiness(e.target.value)} />
 </div>
 <div class="form-group col-md-4">
    <label htmlFor="business">Type of Personal Use</label>
 <input type="text"  className="form-control"  value={personal} onChange={e => setPersonal(e.target.value)} />

  </div>


  <div class="form-group col-md-6">
    <label htmlFor="business">Domain Name</label>
 <input type="text"  className="form-control"  value={domain} onChange={e => setDomain(e.target.value)} />
 
  </div>

    <div class="form-group col-md-4">
      <label htmlFor="pages">Page Numbers </label>
      <select id="pages" class="form-control"  value={pagenumbers} onChange={e => setPagenumbers(e.currentTarget.value)}>
        <option value>Pages... </option>
            <option>Up to 5</option>
            <option>Up to 10</option>
            <option>Up to 20</option>
            <option>Up to 30</option>
            <option>Up to 45</option>
            <option>Unsure</option>
            </select>
            </div>

            <div class="form-group col-md-4">
      <label htmlFor="listings">Ecommerce Listings </label>
      <select id="listings" class="form-control"  value={listings} onChange={e => setListings(e.currentTarget.value)}>
        <option value>Listings...</option>
            <option>Up to 50</option>
            <option>Up to 100</option>
            <option>Up to 200</option>
            <option>Up to 300</option>
            <option>Unsure</option>
            <option>None</option>
            </select>
            </div>
            <br></br> <br></br>

            <div class="form-group col-md-6" style={{display:"none"}}>
    <label htmlFor="website">Website type: React/Node(custom)/Wordpress(template)</label>
 <input type="text"  className="form-control"  value={website} onChange={e => setWebsite(e.target.value)} />
  </div>

            <div class="form-group">
    <label htmlFor="describe1">Describe the purpose of your website [business/personal usage]</label><br></br>
    <textarea type="text" class="form-control" id="describe1" rows="5"  value={describe} onChange={e => setDescribe(e.target.value)}></textarea>
  </div>

  <br></br>
  
  <div class="form-group">
    <label htmlFor="describe2">Describe the desired styles, colors, and functionality of website features</label><br></br>
    <textarea type="text" class="form-control" id="describe2" rows="5"  value={describe2} onChange={e => setDescribe2(e.target.value)}></textarea>
  </div>
  <br></br> <br></br>

  <div>
  <div class="form-group col-md-6">
      <label htmlFor="inputColor"><h5>Color Scheme</h5></label>  <br></br>
        Choose primary layout colors(optional)
       <br></br> <br></br>
       <div className="intro2" style={{textAlign:"justify"}}>Color:  {color1}
      <SketchPicker
            color={color1}
            onChange={(updatedColor) => setColor1(updatedColor.hex)}
          />
    <div className="form-group col-md-4">
Color #1: <input type="text" className="form-control"  value={color2} onChange={e => setColor2(e.target.value)} /> 
Color #2: <input type="text" className="form-control"  value={color3} onChange={e => setColor3(e.target.value)} /> 
</div>
       </div>

       
       </div>
       
       </div>


<br/><br/>


      <div className="col-sm-4">
        <div className="form-check">
          <input  className="form-check-input"  type="checkbox"  checked={agree === "agree"} value="agree" onChange={(event) =>  {
      setAgree(event.target.value);
    }}/><label style={{fontSize:"19px"}} htmlFor="agree" className="form-check-label">I Agree with the <Link onClick={handleShow}>TOS</Link></label>
        </div>
       </div>
      <br/>
   


<br/>

<div><button type="submit" className="apply" onClick={validation}>Apply</button>
</div>




    </div> </form></div>

         </div>
         
</div>






</>
  );
}

