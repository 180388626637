import React, {useState, useContext, memo} from 'react';
import {Link, useNavigate } from "react-router-dom";
import { PayPalButtons } from "@paypal/react-paypal-js";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Tabs, Tab} from "@nextui-org/react";
import logo from "./X.png";
import { OverlayTrigger, Tooltip} from 'react-bootstrap';
import 'react-tooltip/dist/react-tooltip.css';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {AuthContext, EmailContext, ProfileContext} from "./App";
import {firebaseConfig}  from "./App";
import  firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import 'firebase/compat/auth';
import Navigation from './Navigation';

                  
function Pricing() {
  const navigate = useNavigate();
  const {profileOpen} = useContext(ProfileContext);
  const auth = firebase.auth(firebaseConfig);
  const {userEmail} = useContext(EmailContext);
  const {authStatus} = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [message, setMessage] = useState("");
  const [expanded, setExpanded] = React.useState(false);
const [id, setId] = useState("");
const [price, setPrice] = useState("");
const db = firebaseConfig.firestore();
const [cart] = useState([{
  id: id,
  quantity: "1",
  price: price
}]);








 


const handleChange = (panel) => (event, isExpanded) => {
  setExpanded(isExpanded ? panel : false);
};


    



   function insomnia (){
cart[0].price = "1000";
cart[0].id = "Insomnia";
                  
   }
 

 function n1 (){
  cart[0].price = "2000";
  cart[0].id = "N1";

   }

   function n2 (){
    cart[0].price = "3500";
    cart[0].id = "N2";

     }

     function n3 (){
      cart[0].price = "4500";
      cart[0].id = "N3";

       }

       function n35 (){
        cart[0].price = "6000";
        cart[0].id = "N3.5";

         }

         function rem (){
          cart[0].price = "7000";
          cart[0].id = "REM";

           }


 async function createOrder() {

  try {
      const response = await fetch("/api/orders", {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },

          body: JSON.stringify({  
              cart: cart,
          }),
      });

      const orderData = await response.json();

      if (orderData.id) {
          return orderData.id;
      } else {
          const errorDetail = orderData?.details?.[0];
          const errorMessage = errorDetail
              ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
              : JSON.stringify(orderData);

          throw new Error(errorMessage);
      }
  } catch (error) {
      console.error(error);
      window.scrollTo(0, 0);
      setMessage(
      <div>
      <br></br>
      <p><strong>Your transaction could not be processed. Please try again later.</strong></p><br></br><br></br>
      <br></br>
      </div>);
  }
}
  


async function onApprove(data, actions) {
  try {
      const response = await fetch(
          `/api/orders/${data.orderID}/capture`,
          {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
          }
      );

      const orderData = await response.json();



              const errorDetail = orderData?.details?.[0];

              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {

                return actions.restart();
              } else if (errorDetail) {
                throw new Error(
                  `${errorDetail.description} (${orderData.debug_id})`,
                );
              } else {

                const transaction = orderData.purchase_units[0].payments.captures[0];
                 
                db.collection("currentUser").doc(firebase.auth().currentUser.uid+1026).set({
                  email: userEmail,
                  transaction: cart
                
                })
            
                navigate('/apply');
                window.scrollTo(0, 0);
                }
              } catch (error) {
                console.error(error);
                window.scrollTo(0, 0);
                setMessage(
                  <div>
                  <br></br>
                  <p><strong>Your transaction could not be processed. Please try again later.</strong></p><br></br><br></br>
                  <br></br>
                  </div>
              );
            }

          }
        
  

 

  return (
<>

  <div className='intro2'>





      <Modal id="modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
    <Modal.Header style={{backgroundColor:"lightgray"}} closeButton> <h4>Terms of Service</h4> </Modal.Header>
        <Modal.Body style={{backgroundColor:"lightgray"}}>
        <br></br>
Terms<br></br>
The “entity” is Night Tremors Designs. “Services” include website design, website content updates, and website hosting. The “client” is the individual/company requesting services from the entity.
<br></br><br></br>
Website Design Plans<br></br>
All clients receiving services from the entity must agree to pay the amounts requested for the payment plan they choose that are listed on the entity’s website at the time the payment is made. If they do not agree with the payment plan, they must not proceed with the payment for services. Payments for services are fixed fees that must be paid before services are rendered. The level of customization to the website(s) provided to the client is to be determined by the entity while negotiations on the customization of the website(s) may be made between the entity and the client throughout the process of the product development. 
<br></br><br></br>
Refunds<br></br>
Refunds for website design services provided will not be released if the end product that was approved by the client has already been published. A full refund cannot be granted if work on the project had already started at the time of the refund request. Partial refunds of up to 75% of the payment plan that was purchased can be released if the client is unsatisfied with the website design sample previewed before the finished project is published online. Refunds for maintenance fees may be provided if the client and the entity agree that maintenance and hosting will be completed by the client on the month that maintenance fees were paid before services were rendered for the month that the responsibility of maintenance was transferred from the entity to the client. It is the client’s responsibility to promptly send an email to the entity for a refund request detailing the reasoning for the refund request before the work has been published for a website design refund or before maintenance to the website is provided for a maintenance fee refund. The client must allow the entity up to 5 days to process the refund request.
<br></br><br></br>
Product Ownership<br></br>
While the entity is responsible for creating the websites offered and providing hosting for their client’s websites, full ownership of the website is given to the client. In the event that the client requests to discontinue their relationship with the entity, the entity will release the website data to the client or migrate the data to the client’s new website host and discontinue accepting payments for monthly maintenance fees within 14 days of the request.
<br></br><br></br>
Security<br></br>
The security of the website is provided by the web hosting service while payment processing security is provided by the company of the payment processor. In the event that a security issue on the client’s website arises, the client should contact the entity so that the appropriate parties can be contacted in order to address the security concerns.
<br></br><br></br>
Refusal of Service<br></br>
We have the right to refuse service to potential clients for a variety of reasons including but not limited to inappropriate and/or abusive behavior displayed by the client, the client requests websites that include content prohibited by the hosting company such as explicit content and websites created to generate income through gambling, and clients that demand any requests that cannot be fulfilled by the entity. In such cases, refunds will be issued when appropriate.

      <br></br><br></br>     
     <Button variant="secondary" onClick={handleClose}>
            I Agree
          </Button>
     </Modal.Body>
      </Modal>
  

 
<div className="PageT">
      <h3 className='intro2' style={{textAlign:"center", fontSize:"40px", marginBottom:"10px"}}>Web Design Plans</h3>


<div> {message} </div>

<div className='card5'>




                  
                
      <Tabs className='Tabs' aria-label="Options">

        <Tab className='Tab' key="plans1" title="Level 1 Plans" >
          
        <div className= "cards2" >
        <div className='div1' >
        <div className='glow'>
        <h2 style={{textAlign: "center"}}>Insomnia Plan</h2>
      
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Custom Web Design <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Web App / Wordpress website <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Up to 8 pages of content <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Unlimited content updates <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> 50 GB of data storage<br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Website hosting <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Website security <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Custom domain name<br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Domain added to search engines  {window.innerWidth <= 415  ?  "" : <>&emsp;&emsp;&emsp;</>}<br></br>


       <div style={{textAlign:"center"}}>  <hr/>
                 <h3 className='Lfont'>$1,000
                  <br></br>
                or <br></br>
                6 payments of $179 /<br></br> 
                12 payments of $95

             <OverlayTrigger placement={'bottom'} 
      overlay={<Tooltip>Flexible 6 to 24 month payment plans are available through PayPal after credit approval. Current rates vary based on credit and amount of purchase.</Tooltip>}>
             <i style={{color: "#1eee60", fontSize:"17px"}} className="bi bi-question-diamond">
             </i></OverlayTrigger>  </h3> <p style={{fontSize:"13px"}}>with PayPal Pay Later</p>
              <p ><hr/>
                 <h4>Maintenance fee</h4>
                 $50/month
                </p></div>

                {(authStatus)&&(!profileOpen) ? 
           <PayPalButtons style={{ color:"black", layout:"vertical"}}
           createOrder={createOrder}
          onApprove={onApprove} onClick={insomnia} /> 
           : 
           <Link to="/signup" onClick={()=>{window.scrollTo(0, 0)}}><button className="button2">Apply Here</button></Link>}
         
            </div>
            </div>
            



 

            <div className='div1'>
          <div className='glow'>
 <h2 style={{textAlign: "center"}}>N1 Plan</h2>

<img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Custom Web Design <br></br>
<img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Web App / Wordpress website <br></br>
<img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Up to 15 pages of content <br></br>
<img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Unlimited content updates<br></br>
<img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> 50+ GB of data storage<br></br>
<img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Website hosting <br></br>
<img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Website security <br></br>
<img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Custom domain name<br></br>
<img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Domain added to search engines  {window.innerWidth <= 415  ?  "" : <>&emsp;&emsp;&emsp;</>}<br></br>
<div style={{textAlign:"center"}}> <hr/>
                 <h3 className='Lfont'>$2,000 <br></br>
                or <br></br>
                6 payments of $359 / <br></br>
                12 payments of $191

             <OverlayTrigger placement={'bottom'} 
      overlay={<Tooltip>Flexible 6 to 24 month payment plans are available through PayPal after credit approval. Current rates vary based on credit and amount of purchase.</Tooltip>}>
              <i style={{color: "#1eee60", fontSize:"17px"}} className="bi bi-question-diamond">
              </i></OverlayTrigger>  </h3>   <p style={{fontSize:"13px"}}>with PayPal Pay Later</p>
              <p><hr/>
              <h4>Maintenance fee</h4>
              $80/month<br></br>
                </p></div>
            
                {(authStatus)&&(!profileOpen) ? 
                <PayPalButtons style={{ color:"black", layout:"vertical"}}
                onClick={n1}  createOrder={createOrder}
                onApprove={onApprove}  /> 
           : 
           <Link to="/signup" onClick={()=>{window.scrollTo(0, 0)}}><button className="button2">Apply Here</button></Link>}
            
          </div>
      </div>

  

      <div className='div1'>
         <div className='glow'>
        <h2 style={{textAlign: "center"}}>N2 Plan</h2>

        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Custom Web Design <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Web App / Wordpress website <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Up to 30 pages of content <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Unlimited content updates <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> 100 GB of data storage<br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Website hosting <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Website security <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Custom domain name <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Domain added to search engines  {window.innerWidth <= 415  ?  "" : <>&emsp;&emsp;&emsp;</>}<br></br>
       <div style={{textAlign:"center"}}>  <hr/>
                 <h3 className='Lfont'>$3,500<br></br>
                or <br></br>
                6 payments of $628 / <br></br>
                12 payments of $334
             <OverlayTrigger placement={'bottom'} 
      overlay={<Tooltip>Flexible 6 to 24 month payment plans are available through PayPal after credit approval. Current rates vary based on credit and amount of purchase.</Tooltip>}>
              <i style={{color: "#1eee60", fontSize:"17px"}} className="bi bi-question-diamond">
              </i></OverlayTrigger>  </h3>   <p style={{fontSize:"13px"}}>with PayPal Pay Later</p>
              <p><hr/>
              <h4>Maintenance fee</h4>
              $100/month<br></br>
                </p></div>
                {(authStatus)&&(!profileOpen) ? 
                   <PayPalButtons style={{ color:"black", layout:"vertical"}}
                   onClick={n2}  createOrder={createOrder}
                   onApprove={onApprove}  />
              : 
              <Link to="/signup" onClick={()=>{window.scrollTo(0, 0)}}><button className="button2">Apply Here</button></Link>}
            
      </div>
      </div>
      </div>
        
   </Tab>

        <Tab className='Tab' key="plans2" title="Level 2 Plans">
        <div className= "cards2">

        <div className='div1'>
        <div className='glow'>
  <h2 style={{textAlign: "center"}}>N3 Plan</h2>

  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Custom Web Design <br></br>
  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Web App / Wordpress website <br></br>
  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Up to 45 pages <br></br>
  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Unlimited content updates <br></br>
  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> 100 GB of data storage<br></br>
  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Website hosting <br></br>
  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Website security <br></br>
  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Custom domain name <br></br>
  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Domain added to search engines  {window.innerWidth <= 415  ?  "" : <>&emsp;&emsp;&emsp;</>}<br></br>
    <div style={{textAlign:"center"}}><hr/>
                <h3 className='Lfont'>$4,500<br></br>
                or <br></br>
                6 payments of $808 / <br></br>
                12 payments of $430

             <OverlayTrigger placement={'bottom'} 
      overlay={<Tooltip> Flexible 6 to 24 month payment plans are available through PayPal after credit approval. Current rates vary based on credit and amount of purchase.</Tooltip>}>
             <i style={{color: "#1eee60", fontSize:"17px"}} className="bi bi-question-diamond">
             </i></OverlayTrigger>  </h3>  <p style={{fontSize:"13px"}}>with PayPal Pay Later</p>
              <p><hr/>
              <h4>Maintenance fee</h4>
              $100/month<br></br>
                </p></div>

                {(authStatus)&&(!profileOpen) ? 
                  <PayPalButtons style={{ color:"black", layout:"vertical"}}
                  onClick={n3}  createOrder={createOrder}
                  onApprove={onApprove}  /> 
            : 
            <Link to="/signup" onClick={()=>{window.scrollTo(0, 0)}}><button className="button2">Apply Here</button></Link>}
            
      </div>
      </div>


      <div className='div1'>
        <div className='glow'>
  <h2 style={{textAlign: "center"}}>N3.5 Plan</h2>

  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Custom e-commerce website <br></br>
  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Web App / Wordpress website <br></br>
  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Up to 150 product listings <br></br>
  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Unlimited content updates <br></br>
  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> 200 GB of data storage<br></br>
  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Website hosting <br></br>
  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Website security <br></br>
  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Custom domain name  <br></br>
  <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Domain added to search engines  {window.innerWidth <= 415  ?  "" : <>&emsp;&emsp;&emsp;</>}<br></br>
  <div style={{textAlign:"center"}}><hr/>
                 <h3 className='Lfont'>$6,000
                  <br></br>
                or <br></br>
                6 payments of $1,077 /  <br></br>
                12 payments of $573 

             <OverlayTrigger placement={'bottom'} 
      overlay={<Tooltip>Flexible 6 to 24 month payment plans are available through PayPal after credit approval. Current rates vary based on credit and amount of purchase.</Tooltip>}>
              <i style={{color: "#1eee60", fontSize:"17px"}} className="bi bi-question-diamond">
              </i></OverlayTrigger>  </h3>  <p style={{fontSize:"13px"}}>with PayPal Pay Later</p>
              <p><hr/>
              <h4>Maintenance fee</h4>
              $120/month<br></br>
                </p></div>

                {(authStatus)&&(!profileOpen) ? 
            <PayPalButtons style={{ color:"black", layout:"vertical"}}
            onClick={n35}  createOrder={createOrder}
            onApprove={onApprove}  /> 
            : 
            <Link to="/signup" onClick={()=>{window.scrollTo(0, 0)}}><button className="button2">Apply Here</button></Link>}
     </div>
</div>

     <div className='div1'>
      <div className='glow'>
        <h2 style={{textAlign: "center"}}>REM Plan</h2>

        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Custom e-commerce website <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Web App / Wordpress website <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Up to 300 product listings <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Unlimited content updates <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> 200 GB of data storage<br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Website hosting <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Website security <br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Custom domain name<br></br>
        <img style={{width:"23px", height:"26px"}} src={logo} alt="X logo" /> Domain added to search engines  {window.innerWidth <= 415  ?  "" : <>&emsp;&emsp;&emsp;</>}<br></br>
        <div style={{textAlign:"center"}}>  <hr/>
                <h3 className='Lfont'>$7,000
                  <br></br>
                or <br></br>
                6 payments of $1,256 / <br></br>
                12 payments of $669
                 <OverlayTrigger placement={'bottom'} 
      overlay={<Tooltip>Flexible 6 to 24 month payment plans are available through PayPal after credit approval. Current rates vary based on credit and amount of purchase.</Tooltip>}>
              <i style={{color: "#1eee60", fontSize:"17px"}} className="bi bi-question-diamond">
              </i></OverlayTrigger>  </h3>  <p style={{fontSize:"13px"}}>with PayPal Pay Later</p>
              <p><hr/>
              <h4>Maintenance fee</h4>
              $120/month<br></br>
                </p></div>
    
                {(authStatus)&&(!profileOpen) ? 
             <PayPalButtons style={{ color:"black", layout:"vertical"}}
             onClick={rem}  createOrder={createOrder}
             onApprove={onApprove}  />  :
            
             <Link to="/signup" onClick={()=>{window.scrollTo(0, 0)}}><button className="button2">Apply Here</button></Link>}
        
</div>

</div>



</div>

 </Tab>
      </Tabs>
       
 
</div>
<br></br>



 
    <div className='intro'>  

      <div className='box3' style={{marginTop:"2rem", textAlign:"center"}}>
 <h3 className='Lfont'>FAQs</h3> 
      <Accordion style={{backgroundColor: 'rgba(17, 17, 17, 0.996)', color: '#aeaeae'}} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
           expandIcon={ <ExpandMoreIcon style={{color: 'GrayText'}} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0, fontSize: "20px", fontFamily: "Ysabeau Office"  }}>
            How do I receive PayPal credit?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
         
            Click on the Pay Later button to sign up/sign into a PayPal account and choose Pay Later from the list of options to apply for PayPal credit and check your eligibility to receive credit for your purchase.
         </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion  style={{backgroundColor: 'rgba(17, 17, 17, 0.996)', color: '#aeaeae'}} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color: 'GrayText'}} />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0, fontSize: "20px", fontFamily: "Ysabeau Office"  }}>When are monthly maintenance fees due?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           Monthly maintenance fees are due the month after the project is completed and published online. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion  style={{backgroundColor: 'rgba(17, 17, 17, 0.996)', color: '#aeaeae'}} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color: 'GrayText'}} />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0, fontSize: "20px", fontFamily: "Ysabeau Office" }}>
           How do I receive a refund?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If we haven't started on the project or the project is only partially completed, you can receive a full or partial refund.
            Send us an email at nt@support.nighttremorsdesigns.com to request a refund.
            Please review our refunds policy in our <Link style={{color: "#1eee60", fontSize:"20px"}} onClick={handleShow}>Terms of Service</Link>.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion  style={{backgroundColor: 'rgba(17, 17, 17, 0.996)', color: '#aeaeae'}} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color: 'GrayText'}} />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0, fontSize: "20px", fontFamily: "Ysabeau Office"  }}>
            How long will it take for the project to be completed?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
Project completion time depends on the amount of pages and content required for the project as well as the amount
of client changes requested throughout the design process.<br></br><br></br>
Typical project completion estimates:<br></br>
Insomnia Plan - N1 Plan: 2 wks ~ 1 month<br></br>
N2 Plan - N3 Plan: 1.5 ~ 2 months<br></br>
N3.5 - REM Plan: 2 ~ 3 months

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion  style={{backgroundColor: 'rgba(17, 17, 17, 0.996)', color: '#aeaeae'}} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color: 'GrayText'}} />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0,  fontSize: "20px", fontFamily: "Ysabeau Office"  }}>
            What types of websites do you create?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
We create a variety of websites mainly focusing on small to mid-sized businesses. However, we don't create websites with explicit content
or websites designed to generate income from gambling. If you're unsure if we create the type of website you want, then just 
email us at nt@support.nighttremorsdesigns.com and share the details of your desired website before making a purchase.

          </Typography>
        </AccordionDetails>
      </Accordion>


      

    </div>   
    <br></br>
<p style={{fontSize:"15px"}}><Link style={{color: "#1eee60"}} onClick={handleShow}>Terms of Service </Link> <br></br>
      We accept payments from <a style={{fontSize:"18px"}} href="https://www.paypal.com/us/legalhub/acceptableuse-full?_ga=1.227700317.1941729807.1720398083" target="_blank" rel="noValueer noreferrer">PayPal</a>, PayPal credit, & Debit/Credit cards. <br></br>
      <i style={{color: "#1eee60", fontSize:"12px"}} className="bi bi-question-diamond">
              </i> All monthly payment rates provided by PayPal vary based on credit history and the amount of the purchase. <br></br><br></br>

      </p>

      
      </div>
</div> 
    





    </div>
 </>
  );
}


export default Pricing;