import React,{ createContext, useState, useContext, useCallback, Redirect} from 'react';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import Navigation from './Navigation';
import Home from './home';
import About from './about';
import Apply from './apply';
import Pricing from './pricing';
import Profile from './profile';
import logo from "./Logo2.png";
import LoggedIn from "./login";
import SignedUp from "./signup";
import  firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import 'firebase/compat/auth';


export const firebaseConfig = firebase.initializeApp({
  apiKey: "AIzaSyBOk57v8SSTVBmYo8hoKZ9RDk5QfbHjQPo",
  authDomain: "ntapp24.firebaseapp.com",
  projectId: "ntapp24",
  databaseURL: "https://ntapp24.firebaseio.com",
  storageBucket: "ntapp24.appspot.com",
  messagingSenderId: "705234714551",
  appId: "1:705234714551:web:060c649da29e57b09cf232",
  });
  




export const AuthContext = createContext("");
export const ProfileContext = createContext("");
export const CurrentUserContext = createContext("");
export const EmailContext = createContext("");
 





function App(){

      const [authStatus, setAuthStatus] = useState(false);
    const [currentUser, setCurrentUser] = useState([]);
    const [profileOpen, setProfileOpen] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [name1, setName1] = useState("");
    const db = firebaseConfig.firestore();     
   const auth = firebase.auth(firebaseConfig);




   const data1 = useCallback(() => 
    firebase.auth().onAuthStateChanged ((user) => {  
                 
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL) 
if (user!==null) {  
 
           setAuthStatus(true);     
           setUserEmail(user.email);
           
}

      
       if (name1!==null){

            setProfileOpen(true); 
             db.collection("currentUser").where("email", "==", user.email).onSnapshot((snapshot) => {
               
             setCurrentUser(
               snapshot.docs.map((doc) => ({
                  
                 id: doc.id,  
                 user: user.email,
                 data: doc.data(),
             
               }) )
       
             );
             
             }
            
          
            )} 
    currentUser?.map(( {data} ) => {
            setName1(data.name);
  })
  }, [])

      
)
        
   
  

  
const initialOptions = {
  "client-id": "AS9Zq5L4edD62vIJrkvCnEnOsWM-xFHp8fL_Dhdux_r8KjVaCLYLJRWI1XuXX5GAag-tthInfr8iBb__",
  currency: "USD",
  intent: "capture"
};

  return (
    <div className="App" onLoad={data1}>
    <BrowserRouter>
  <PayPalScriptProvider options={initialOptions}>
  <AuthContext.Provider value={{authStatus}}>
  <EmailContext.Provider value={{userEmail}}>
  <ProfileContext.Provider value={{profileOpen}}>
  <CurrentUserContext.Provider value={{currentUser}}>
<Navigation/>
   <Routes>
    
   <Route path="/"  element={<Home />}></Route>
   <Route path="/about"  element={<About/>}></Route>
   <Route path="/pricing"  element={<Pricing/>}></Route>

   {authStatus && !profileOpen ? 
   <Route path="/apply"  element={<Apply/>}></Route> : ""}
  {profileOpen ? <Route path="/profile"  element={<Profile/>}></Route> :  
 ""}
 <Route path="/login"  element={<LoggedIn/>}></Route>
   <Route path="/signup"  element={<SignedUp/>}></Route>


  

</Routes>
       <div> 
<div className='box7' style={{justifyContent:"space-evenly",flexWrap:"wrap", flexDirection:"row",display:"flex", textAlign:"center", width:"100%", position:"relative"}}>
<div><a href="/about">About Us</a> <br></br>
  <Link onClick={() => window.location = 'mailto:nt@support.nighttremorsdesigns.com'}>Contact Us</Link>
</div>
  <div><img style={{width:"12rem", marginTop:"5px"}} src={logo} alt="Night Tremors Designs Logo" /></div> 
  <div><a href="https://www.instagram.com/nighttremorsdesignsx" target="_blank" rel="noreferrer"><button className='iconfooter' ><i className="bi bi-instagram"></i> IG</button></a></div>

</div></div>

</CurrentUserContext.Provider>
</ProfileContext.Provider>
</EmailContext.Provider>
</AuthContext.Provider>    
</PayPalScriptProvider>
</BrowserRouter> 

    </div>
  )}


export default App;
