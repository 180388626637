import React from 'react';
import {Link } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import Logo2 from "./Logo2.png";
import green from "./green1.jpg";
import Navigation from './Navigation';



function About() {

  var width;
  var show;
{window.innerWidth <= 420  ?  width="100%" : width="50%"};
{window.innerWidth <= 420  ?  show="none" : show="block"};

  return (
<div style={{fontSize:"18px"}} >

 <div className='PageT3' >
<div className='about2' >





<div >
 <h3 className='intro2' style={{textAlign:"center", fontSize:"40px", marginBottom:"10px"}}>Night Tremors Designs</h3>

   <div className='row'>
   <div className='column'>
    
    <div className='box2' style={{ width:width}} >

 
  <p className='about1' style={{padding:"5px"}}>  
 <Image src={green} style={{width:"50%", display:show}} className='img2'  alt="Night Tremors Designs Mask" />

Night Tremors Designs is based in Forest Park, Georgia and was designed to create more accessible website designs
for individuals who need web design services that work for a variety of budgets. We aim to create a space for more unique designs 
that capture the attention of users and converts users into customers for business owners. We work hard to create a positive and 
original brand image for companies and personal, creative looks for portfolios. <br></br>
</p> 
 </div></div>
</div></div>

<div className='intro'></div>


</div>
</div>

<br></br>
<br></br>

       
<div >
        <div  style={{justifyItems:"center"}}><h3 style={{textAlign:"center", marginBottom:"5rem", fontSize:"35px"}}>Our Objectives</h3>
          <div className='box5' >
          <i style={{color: "#1eee60", fontSize:"20px"}} className='bi bi-universal-access-circle'></i>&emsp;To make our plans more accessible<br></br>
          </div><br></br>
          <div className='box5' >
          <i style={{color: "#1eee60", fontSize:"20px"}} className='bi bi-credit-card-fill'></i>&emsp;To make payment options more flexible <br></br> 
          </div><br></br>
          <div className='box5' >
          <i style={{color: "#1eee60", fontSize:"20px"}} className='bi bi-brush'></i>&emsp;To make attractive & creative designs<br></br>
          </div><br></br>
          <div className='box5' >
          <i style={{color: "#1eee60", fontSize:"20px"}} className='bi bi-easel'></i>&emsp;To create a smooth design process<br></br>
          </div><br></br>
          <div className='box5'>
          <i style={{color: "#1eee60", fontSize:"20px"}} className='bi bi-person-workspace'></i>&emsp;To negotiate & work with our clients <br></br>
          </div><br></br>
          <div className='box5' >
          <i style={{color: "#1eee60", fontSize:"20px"}} className='bi bi-window-fullscreen'></i>&emsp;To present a functional & interactive UI<br></br>
          </div></div>
       <br></br>
       <br></br>
       <br></br>
</div><div className='row'>
<div className='cards3'> 
<div className='box2' style={{marginBottom:"5rem", position:"relative", textAlign:"center"}}>
      <br></br>
 <h3>Contact Us</h3>
 
 <br></br> 
<p style={{fontSize:"17px", color: "#1eee60"}}>
<Link onClick={() => window.location = 'mailto:nt@support.nighttremorsdesigns.com'}>
<i style={{color: "#1eee60", fontSize:"22px"}} className="bi bi-envelope">
 </i> </Link>nt@support.nighttremorsdesigns.com</p></div>
</div>
    </div>
</div>


  );
}

export default About;