import React, { useContext, useState, useMemo} from "react";
import {Link, useNavigate} from "react-router-dom";
import {CurrentUserContext, ProfileContext} from "./App";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import  firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import 'firebase/compat/auth';
import {firebaseConfig}  from "./App";
import Navigation from "./Navigation";



function Profile () { 
  const auth = firebase.auth(firebaseConfig);

  const [name, setName] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
 const [domain, setDomain] = useState("");
     const [describe, setDescribe] = useState("");
     const [describe2, setDescribe2] = useState("");
     const [show, setShow] = useState("none");
     const db = firebaseConfig.firestore();
     const {profileOpen} = useContext(ProfileContext);
  const {currentUser} = useContext(CurrentUserContext);
  const navigate = useNavigate();


  if (!profileOpen){
    return navigate('/home');}

  let USDcurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
   





function data2(){
if (currentUser[0].data.transaction[0].id==="Insomnia"){
  return <div>
  <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Custom Web Design Package<br></br>
  <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Web App / Wordpress website <br></br>
  <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Up to 8 pages of content <br></br>
  <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Unlimited content updates <br></br>
  <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> 50 GB of data storage<br></br>
  <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Website hosting <br></br>
  <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Website security <br></br>
  <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Custom domain name<br></br>
  <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Domain added to search engines </div>
}
if (currentUser[0].data.transaction[0].id==="N1"){
  return <div>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Custom Web Design Package<br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Web App / Wordpress website <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Up to 15 pages of content <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Unlimited content updates<br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> 50+ GB of data storage<br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Website hosting <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Website security <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Custom domain name<br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Domain added to search engines 
  </div>
}
if (currentUser[0].data.transaction[0].id==="N2"){
  return <div>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Custom Web Design Package<br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Web App / Wordpress website <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Up to 30 pages of content <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Unlimited content updates <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> 100 GB of data storage<br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Website hosting <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Website security <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Custom domain name <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Domain added to search engines
  </div>
}

if (currentUser[0].data.transaction[0].id==="N3"){
  return <div>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Custom Web Design Package<br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Web App / Wordpress website <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Up to 45 pages of content <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Unlimited content updates <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> 100 GB of data storage<br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Website hosting <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Website security <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Custom domain name <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Domain added to search engines
  </div>
}

if (currentUser[0].data.transaction[0].id==="N3.5"){
  return <div>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Custom E-commerce Package<br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Web App / Wordpress website <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Up to 150 product listings <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Unlimited content updates <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> 200 GB of data storage<br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Website hosting <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Website security <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Custom domain name <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Domain added to search engines
  </div>
}

if (currentUser[0].data.transaction[0].id==="REM"){
  return <div>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Custom E-commerce Package<br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Web App / Wordpress website <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Up to 300 product listings <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Unlimited content updates <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> 200 GB of data storage<br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Website hosting <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Website security <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Custom domain name <br></br>
 <i style={{color:"#1eee60", fontSize:"17px"}} class="bi bi-gem"></i> Domain added to search engines
  </div>
}

}

function showEdit(){

  if (currentUser[0].data.progress==="started"||currentUser[0].data.progress==="not started") {
      setShow("block");
      
  }
}

function show1(){

  if (currentUser[0].data.progress==="started"||currentUser[0].data.progress==="not started") {
    document.getElementById("name").style.display="block";
    document.getElementById("name1").style.display="block";

    
  }
}



function show4(){

  if (currentUser[0].data.progress==="started"||currentUser[0].data.progress==="not started") {
      document.getElementById("describe1").style.display="block";
      document.getElementById("describe3").style.display="block";
      
  }
}

function show5(){

  if (currentUser[0].data.progress==="started"||currentUser[0].data.progress==="not started") {
      document.getElementById("describe2").style.display="block";
      document.getElementById("describe33").style.display="block";
    
  }
}

function show6(){

  if (currentUser[0].data.progress==="started"||currentUser[0].data.progress==="not started") {
      document.getElementById("domain").style.display="block";
      document.getElementById("domain1").style.display="block";
      
  }
}

function show7(){

  if (currentUser[0].data.progress==="started"||currentUser[0].data.progress==="not started") {
      document.getElementById("phone").style.display="block";
      document.getElementById("phone1").style.display="block";
      
  }
}

function updateName() {
if (name!==""){
  db.collection("currentUser").doc(firebase.auth().currentUser.uid+1026).update({
    name: name,

  }, { merge: true });
}
}

function updatePhone() {

  if(phonenumber!==""){
  db.collection("currentUser").doc(firebase.auth().currentUser.uid+1026).update({
    phonenumber : phonenumber,


  }, { merge: true });
}
}

function updateDes() {

  if (describe!==""){
  db.collection("currentUser").doc(firebase.auth().currentUser.uid+1026).update({
    describe: describe


  }, { merge: true });
}
}

function updateDes2() {
if (describe2!==""){
  db.collection("currentUser").doc(firebase.auth().currentUser.uid+1026).update({
    describe2: describe2


  }, { merge: true });
}
}

function updateDomain() {
if (domain!==""){
  db.collection("currentUser").doc(firebase.auth().currentUser.uid+1026).update({
    domain: domain


  }, { merge: true });
}
}

function Main(){

  return(
 currentUser?.map(( {data} ) => (
     
     
    <div key={currentUser} className='profile'>


   <div className='box' style={{backgroundColor:"black", marginBottom:"10px"}}>
   <div><i style={{color:"#1eee60", fontSize:"20px"}} className="bi bi-person-bounding-box"></i> Logged In...</div>
   
   <br></br>
   <i style={{color:"#1eee60", fontSize:"70px"}} className="bi bi-file-earmark-person"></i>

   <br></br>
   
   <table>
   
   <tbody className="table">
   <tr >
        <th scope="row">Name </th>
          <td>{data.name} 
           <input type="text" id="name"  style={{display:"none"}}  className="form-control"  value={name} onChange={e => setName(e.target.value)} /></td><i style={{display:show}} onClick={show1}  className="bi bi-pencil-square"></i>  
         <td className="box9" id="name1" style={{display:"none"}}> <i style={{backgroundColor:"transparent", color:"#1eee60", fontSize:"17px"}} className="bi bi-arrow-bar-up"></i> <Link  style={{ backgroundColor:"transparent", fontSize:"18px", color:"#1eee60"}} onClick={updateName}>update</Link></td>
      </tr>
      <tr>
        <th scope="row">Email</th>
          <td>{data.email}</td>  
      </tr>
      <tr>
        <th scope="row">Phone Number </th>
          <td> {data.phonenumber}
            <div id="phone"  style={{display:"none", backgroundColor:"transparent"}} >
          <PhoneInput 
    type="text"
    placeholder=""
    value={phonenumber}
    onChange={setPhoneNumber}/></div></td>
    <i style={{display:show}} onClick={show7} className="bi bi-pencil-square"></i> <td className="box9"  id="phone1" style={{display:"none"}}><i style={{backgroundColor:"transparent", color:"#1eee60", fontSize:"17px"}} className="bi bi-arrow-bar-up"></i> <Link style={{ backgroundColor:"transparent", fontSize:"18px", color:"#1eee60"}} onClick={updatePhone} >update</Link></td>
      </tr>
      </tbody>
      </table>
   </div>
   
   <div className="box" style={{backgroundColor:"black", marginBottom:"10px"}}>
   
   <div> <i style={{color:"#1eee60", fontSize:"20px"}} className="bi bi-folder"></i> Projects</div>
   <br>
   </br>
   <div >
   <strong>{data.transaction[0].id}</strong>
   <br></br>
  {data2()}
  <br></br>
  <strong>{USDcurrency.format(data.transaction[0].price)} PAID</strong>
   </div>
  
   
   <br></br>
   </div>
  
   
<div className="profileR">   

<div className='box' style={{backgroundColor:"black", marginBottom:"10px"}}>
    <div><i style={{color:"#1eee60", fontSize:"20px"}} className="bi bi-pc-display"></i> Website Preferences</div>
    <br></br>
   <table>
   
    <tbody className="table" >
       <tr>
         <th scope="row">Website Type</th>
           <td >{data.website}</td>  
       </tr>
       <tr>
         <th scope="row">Business Use</th>
           <td>{data.business}
           </td>  
       </tr>
       <tr>
         <th scope="row">Personal Use</th>
           <td>{data.personal}</td>  
       </tr>
       <tr>
         <th scope="row">Listings</th>
           <td>{data.listings}</td>  
       </tr>
       <tr>
         <th scope="row">Page numbers</th>
           <td >{data.pagenumbers}</td>  
       </tr>
       <tr>
         <th scope="row">Domain name </th>
           <td>{data.domain}
            <div id="domain" style={{background:"transparent", display:"none"}}>
           <input type="text"   className="form-control"  value={domain} onChange={e => setDomain(e.target.value)} /></div></td><i style={{display:show}} onClick={show6} className="bi bi-pencil-square"></i>
           <td className="box9"  id="domain1" style={{display:"none"}}><i style={{backgroundColor:"transparent", color:"#1eee60", fontSize:"17px"}} className="bi bi-arrow-bar-up"></i> <Link style={{ backgroundColor:"transparent", fontSize:"18px", color:"#1eee60"}} onClick={updateDomain}> update</Link></td>  
       </tr>
       <tr>
         <th scope="row">Purpose of the website<br></br> 
          [business/personal usage] </th>
           <td>{data.describe}
           <div className="form-group" id="describe1" style={{background:"transparent", display:"none"}}>
            <textarea type="text"  className="form-control"  rows="5"  value={describe} onChange={e => setDescribe(e.target.value)}></textarea></div></td> <i style={{display:show}} onClick={show4} className="bi bi-pencil-square"></i><br></br>
           <td className="box9"  id="describe3" style={{display:"none"}}><i style={{backgroundColor:"transparent", color:"#1eee60", fontSize:"17px"}} className="bi bi-arrow-bar-up"></i><Link style={{ backgroundColor:"transparent", fontSize:"18px", color:"#1eee60"}} onClick={updateDes}> update</Link> </td>
       </tr>
       <tr>
         <th scope="row">Desired styles, colors, and functionality of features</th> 
           <td>{data.describe2}
           <div  id="describe2" style={{background:"transparent", display:"none"}}>
           <textarea type="text" className="form-control"  rows="5"  value={describe2} onChange={e => setDescribe2(e.target.value)}></textarea></div></td> <i style={{display:show}} onClick={show5} className="bi bi-pencil-square"></i><br></br>
           <td className="box9"  id="describe33" style={{display:"none"}}><i style={{backgroundColor:"transparent", color:"#1eee60", fontSize:"17px"}} className="bi bi-arrow-bar-up"></i><Link style={{ backgroundColor:"transparent", fontSize:"18px", color:"#1eee60"}} onClick={updateDes2}> update</Link> </td>
       </tr>
       <tr>
         <th scope="row">Color 1</th>
           <td>{data.color2}</td>  
       </tr>
       <tr>
         <th scope="row"> Color 2</th>
           <td>{data.color3}</td>  
       </tr>
     </tbody>
    
   </table>

</div>
  </div>

<div>
     <div className="box" style={{backgroundColor:"black", marginBottom:"10px"}}>

<div><i style={{color:"#1eee60", fontSize:"20px"}} className="bi bi-hourglass-split"></i> Progress...</div>
<br>
</br>
<div >
{data.progress}
{progress()}
  <br></br>
 {state}
</div>


</div>

     
   <div className="box" style={{backgroundColor:"black", marginBottom:"10px"}}>

<div><i style={{color:"#1eee60", fontSize:"20px"}} className="bi bi-gear-fill"></i> Settings</div>
<br>
</br>
<div >
<Link style={{fontSize:"25px"}} onClick={showEdit}>Update Info</Link>
</div>


</div>
  </div>
   </div>

     )))
    }

 var progressbar;
 var width;
 var state;

function progress(){ 

    if (currentUser[0].data.progress==="not started"){
     progressbar = 1; width = "1%"; state = <p style={{color:"#1eee60"}}>Site Preview Link: url pending...</p>}

     if (currentUser[0].data.progress==="started"){
      progressbar = 5; width = "5%"; state = <p style={{color:"#1eee60"}}>Site Preview Link: url pending...</p>}

    if (currentUser[0].data.progress==="sample 1 submitted"){
        progressbar = 25; width = "25%"; state = <>Site Preview Link: <p style={{color:"#1eee60"}}>{currentUser[0].data.url}</p></>}
        
    if (currentUser[0].data.progress==="sample 2 submitted"){
        progressbar = 50; width = "50%"; state = <>Site Preview Link: <p style={{color:"#1eee60"}}>{currentUser[0].data.url}</p></>}
   
    if (currentUser[0].data.progress==="finishing up"){
      progressbar = 75; width = "75%"; state = <>Site Preview Link: <p style={{color:"#1eee60"}}>{currentUser[0].data.url}</p></>}

    if (currentUser[0].data.progress==="project complete"){
      progressbar = 100; width = "100%"; state = <p style={{color:"#1eee60"}}>. . .</p>}
  

  
    return (
  <div className="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow={progressbar} aria-valuemin="0" aria-valuemax="100">
  <div className="progress-bar progress-bar-striped bg-success progress-bar-animated" style={{width: width}}></div>
</div>)
}





        return (

<div>



 <div><h3 style={{textAlign:"center", fontSize:"35px"}}> Progress</h3></div> <br></br> 
<div className="profileBG">
<form>
 
{Main()}

 



</form>
 </div>

</div>


  
       
        );
    }
    
    export default Profile;