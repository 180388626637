import React, { useState, useContext, Redirect } from "react";
import {useNavigate } from "react-router-dom";
import {AuthContext, ProfileContext} from "./App";
import {firebaseConfig} from "./App";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import "firebase/compat/firestore";
import Navigation from "./Navigation";


  export default function SignedUp() {
    const auth = firebase.auth(firebaseConfig);
  const [emailerror, setEmailerror] = useState("");
  const [passerror, setPasserror] = useState("");;
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const {authStatus} = useContext(AuthContext);
    const {profileOpen} = useContext(ProfileContext);
    const [emailExists, setEmailExists] = useState(false);




    function SignUp (e) {

      e.preventDefault(); 


      firebase.auth().createUserWithEmailAndPassword(email, password)
  .then((userCredential) => {
          var user = userCredential.user;
           navigate('/pricing');
      window.scrollTo(0, 0);
       })
       
        .catch((error) => {
          if (error.code==='auth/email-already-in-use'){
           setEmailExists(true);
          }
      })
        

  
    }

    function signOut (e) {
      e.preventDefault(); 
      firebase.auth().signOut().then(() => {

      })
      .catch((e)=>{
       console.error(e);
      });
    
  }

    function validation () {

        if (!email) {
          setEmailerror(<p style={{color:"#1eee60"}}> <i style={{color:"#1eee60", fontSize:"14px"}} className="bi bi-exclamation-triangle"></i> email required!</p> )}
    else {
          setEmailerror("");
    }
    if (emailExists){
      setEmailerror(
<p style={{color:"#1eee60"}}> <i style={{color:"#1eee60", fontSize:"14px"}} className="bi bi-exclamation-triangle"></i> 
e-mail already exists.</p>)
}

        if (!password) {
      setPasserror(<p style={{color:"#1eee60"}}> <i style={{color:"#1eee60", fontSize:"14px"}} className="bi bi-exclamation-triangle"></i> password required!</p> )}
        else {
        setPasserror("");
      }
        }
      


    
      
  return (
    <>

    <div className="intro2">

    <br></br>
 <br></br>
 

 <div >
<div className='card1'>
<form  onSubmit={SignUp}>

      <h3>Sign Up</h3><br/>
       <div className="form-group col-md-8">
      <label htmlFor="email1">Email</label>
 <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)}  required />   
 <div id="email1">{emailerror}</div>
    </div>
 
    <div className="form-group col-md-8">
      <label htmlFor="password">Password</label>
 <input type="password" className="form-control"  autoComplete="off" value={password} onChange={e => setPassword(e.target.value)} required  />     
 <div id="password">{passerror}</div>
    </div>


    {!authStatus ? <button style={{marginTop:"10px"}} type="submit" className="signup" onClick={validation}>Sign Up</button>: ""} 


</form>
    {authStatus ? <div><button style={{marginTop:"10px"}} type="submit" className="logout" onClick={signOut} >Log out</button></div>: ""}
</div>
<div style={{paddingBottom:"30rem"}}></div>
 </div> 
 </div>
 </> )
 }
