import React from 'react';
import {Link } from "react-router-dom";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Image from 'react-bootstrap/Image';
import smilie2 from "./redsmilie2.jpg";
import devices from "./devices.jpg";
import website from "./website.jpg";
import code from "./code2.jpg";
import logo from "./X.png";
import Logo2 from "./Logo2.png";
import Navigation from './Navigation';


function Home() {

  var naturalSlideHeight;
  var naturalSlideWidth;


  var box = 'box0';

  if (window.innerWidth > 1300 ){
    naturalSlideWidth=58;
    naturalSlideHeight=25;
  }

if (window.innerWidth === 1024 || !(window.innerWidth > 1300) ){
  naturalSlideWidth=51;
  naturalSlideHeight=28;
}

if (window.innerWidth < 1024){
naturalSlideWidth=40;  
naturalSlideHeight=30;
}

if (window.innerWidth < 413){
  box="box6";

  }



  return (
 
    <div >
      
      
<div className='image11'>
  

<Image className='image' src={smilie2} alt="Night Tremors Designs Mask" />

<div  style={{padding: "20px",marginBottom:"13rem"}}>
<Image className='cards1' src={Logo2} alt="Night Tremors Designs" /><br></br><br></br>
<i style={{color: "#1eee60", fontSize:"17px"}} className="bi bi-exclamation-triangle">
 </i>We create memorable designs for businesses & individuals.<br></br><br></br>
 <i style={{color: "#1eee60", fontSize:"17px"}} className="bi bi-exclamation-triangle">
 </i>We help clients gain online traffic with appealing user interfaces.<br></br><br></br>
 <i style={{color: "#1eee60", fontSize:"17px"}} className="bi bi-exclamation-triangle">
 </i>We help professionals attract & maintain more customers online.
</div>
 </div>



 <div className='PageT2'>
    

   <div className='image3'>
 

  


<div className='WEB' style={{ width:"40%"}}>

<i className="bi bi-pen" style={{fontSize:"40px"}}></i> Plan &emsp;
 <p style={{fontSize:"26px"}}>We Plan the Design based on your needs &emsp;</p>
<i className="bi bi-code-slash" style={{fontSize:"40px"}}></i> Design  &emsp;
<p style={{fontSize:"26px"}}>We Make your Design come to life &emsp;</p>
<i className="bi bi-check-square" style={{fontSize:"40px"}}></i> Host<br></br>
<p style={{fontSize:"26px"}}>We Host the completed project &emsp;</p>
</div>

<div className='NT'>
<div className='box0' style={{marginBottom:"2rem", textAlign:"center"}}>
      <br></br>
 <h3 className='Lfont' >Contact Us</h3>
 
 <br></br> 
<p style={{fontSize:"17px", color: "#1eee60"}}>
<Link onClick={() => window.location = 'mailto:nt@support.nighttremorsdesigns.com'}>
<i style={{color: "#1eee60", fontSize:"22px"}} className="bi bi-envelope">
 </i> </Link>nt@support.nighttremorsdesigns.com</p></div>
</div>







 </div>

 </div>
 <div className='intro'></div>
  <h3 style={{color:"#1eee60", fontSize:"40px", textAlign:"center"}}>We Design</h3><br></br>
  <div>

  
<div className='cards2'>
<div className="container">
<div className="card">
  
    <div className="slide slide1">
        <div className="content"  >
            <div className="iconP" >
          <div className='icon'>
            <i style={{color: "#1eee60", fontSize:"57px"}} className="bi bi-person-square">
  </i><br></br><h3 style={{color:"#1eee60"}}>Personal & Portfolio Websites</h3> </div>
           </div>
        </div>
    </div>

    <div className="slide slide2">
        <div className="content">
        <img style={{width:"18px", height:"20px"}} src={logo} alt="X Logo" /> Promote yourself & your services<br></br>
        <img style={{width:"18px", height:"20px"}} src={logo} alt="X Logo" /> Create articles, blogs, and web pages<br></br>
        <img style={{width:"18px", height:"20px"}} src={logo} alt="X Logo" /> Share your unique messages with the world 
        </div>
    </div>
</div>
</div>


<div className="container">
<div className="card">
    <div className="slide slide1">
        <div className="content">
            <div className="iconB">
              <div className='icon'>
            <i style={{color: "#1eee60", fontSize:"57px"}} className="bi bi-briefcase">
</i><br></br><h3 className='header' style={{color:"#1eee60"}}>Business Websites</h3></div>
            </div>
        </div>
    </div>

    <div className="slide slide2">
        <div className="content"><br></br>
        <img style={{width:"18px", height:"20px"}} src={logo} alt="X Logo" /> Showcase your company's services<br></br>
        <img style={{width:"18px", height:"20px"}} src={logo} alt="X Logo" /> Maintain your business in a digital market <br></br>
        <img style={{width:"18px", height:"20px"}} src={logo} alt="X Logo" /> Boost your profits with an appealing design

        </div>
    </div>
</div>
</div>

<div className="container">
<div className="card">
    <div className="slide slide1">
        <div className="content">
            <div className="iconE">
              <div className='icon'>
            <i style={{color: "#1eee60", fontSize:"57px"}} className="bi bi-cart3">
  </i> <br></br><h3 className='header' style={{color:"#1eee60"}}>E-commerce Websites </h3></div>
            </div>
        </div>
    </div>

    <div className="slide slide2">
        <div className="content">
        <br></br><br></br>
        <img style={{width:"18px", height:"20px"}} src={logo} alt="X Logo" /> Give your brand a unique public presence<br></br>
        <img style={{width:"18px", height:"20px"}} src={logo} alt="X Logo" /> Create an interactive ecommerce website<br></br>
        <img style={{width:"18px", height:"20px"}} src={logo} alt="X Logo" /> Display your products & services to customers
        </div>
    </div>
</div>

</div>

</div>
</div>
 <br></br>
   


      

      <div className='intro2'>

      



 <div className='mobile'>
 <h3 style={{color:"#1eee60", fontSize:"30px"}}>We Offer</h3><br></br>
 <CarouselProvider
        naturalSlideWidth={30}
        naturalSlideHeight={38}
        totalSlides={3}
      >
        <Slider>
          <Slide index={0}>
            <div className='cards2' >
            
          <div className='box'>
             <img className='img2' src={code} style={{height: "14rem"}}  alt='coding and web design' /><br></br>
             <h4 style={{color:"#1eee60",textAlign:"center"}}>Custom Coding & Design</h4><br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}We choose the best layouts
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}We customize website templates
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}We build custom e-commerce sites
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}We create professional designs
          <br></br>
          </div>
</div>
</Slide>
          <Slide index={1}>
          <div> 
            <div className='box'>
          <img className='img2' src={devices} style={{height: "14rem"}} alt='pc webdesign' /><br></br> 
         <h4 style={{color:"#1eee60",textAlign:"center"}}>Multi-device Web Designs</h4> <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}Our designs are responsive
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}Our designs are for various devices
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}Our work can attract various clients
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}Clients reach you from many sources
           <br></br>
          </div>
</div>
          </Slide>
          <Slide index={2}>
          <div>
           <div className='box'>
<img className='img2' src={website} alt='pc web design' /><br></br>
<h4 style={{color:"#1eee60",textAlign:"center"}}>Web Design Reviews</h4><br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}Detail your expectations
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}Preview various design samples
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}The final web design is created
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}The finished product is published
          <br></br>
          </div>
</div>
          </Slide>
        </Slider>
        <ButtonBack>{'<'}</ButtonBack>
        <ButtonNext>{'>'}</ButtonNext>
      </CarouselProvider>
  </div>
      

 
  <div className="tablet"> 
  <h3 className='webservices1'>Our Web Design Services</h3>
  <CarouselProvider 
       naturalSlideWidth={naturalSlideWidth}
       naturalSlideHeight={naturalSlideHeight}
        totalSlides={3}> 
        <Slider >
          <Slide index={0} >
            <div className='cards1' >
            
          <div className='box' >
             <img className="img3" style={{  width: "100%"}} src={code} alt='coding and web design' />
          
             <h3>We take care of all the technical work</h3><br></br> 
                <p style={{textAlign: "left"}}>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}We choose the best layout for your needs
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}We create custom websites with React/Node.js
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo"/>
          {' '}We build customized Wordpress e-commerce websites
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}We perform all the coding and designs for you
          <br></br></p>
          </div>
</div>
</Slide>
          <Slide index={1}>
          <div className='cards1'> 
            <div className='box'>
          <img  className='img3' src={devices} alt='pc web design'  />
         <h3>Our designs are multi-device friendly</h3> <br></br>
         <p style={{textAlign: "left"}}>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}Our work attracts a variety of users
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}Our designs are made for various devices
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}Our designs are made to be responsive
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}Clients can reach you from various sources  
          <br></br>
          </p>
          
          </div>
</div>
          </Slide>
          <Slide index={2}>
          <div className='cards1'>
           <div className='box'>
<img  className='img3' src={website} alt='pc web design'  />
<h3>Review the Design</h3><br></br>
<p style={{textAlign: "left"}}>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}Detail your expectations for the website
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}Preview a work sample of the design created
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}We complete the design considering your input
          <br></br>
          <img style={{width:"23px", height:"25px"}} src={logo} alt="X Logo" />
          {' '}The finished product is published
         
          <br></br> </p>
          </div>
</div>
          </Slide>
        </Slider>
        <ButtonBack>{'<'}</ButtonBack>
        <ButtonNext>{'>'}</ButtonNext>
      </CarouselProvider>
      <br></br>
 </div>

<br></br>

    

<br></br>

<h3 style={{fontSize:"40px"}} className="webplans">Web Design Plans include</h3>

<div className='row'>



      <div className='column' >

  
   
 
<div className='box6'> 
<div className={box}>

<h3 style={{textAlign:"center", color: "#1eee60"}}><i style={{color: "#1eee60", fontSize:"32px"}} className="bi bi-brush">
</i> Fully Customized Website</h3>     
<ul><i style={{color: "#1eee60", fontSize:"32px"}} className="bi bi-filetype-js">
</i> Custom React/Nodejs Web App <br></br>
<ul><i style={{fontSize:"17px"}} className="bi bi-check2">
  </i> Great option for clients that want unlimited customizations </ul> </ul>
<ul><i style={{color: "#1eee60", fontSize:"32px"}} className="bi bi-filetype-php">
  </i> Custom Wordpress website<br></br>
<ul><i style={{fontSize:"17px"}} className="bi bi-check2">
</i> Great option for clients that want a professional website that is easier to edit and manage
</ul></ul>
<ul><i style={{color: "#1eee60", fontSize:"32px"}} className="bi bi-file-earmark-binary">
 </i> Up to 45 webpages allowed <br></br>
<ul><i style={{fontSize:"17px"}} className="bi bi-check2">
 </i> Various amounts of content, features and effects on each webpage. </ul></ul>
</div></div> <br></br>

<div className='box6'> 
<div className={box}>
<h3 style={{textAlign:"center", color: "#1eee60"}}><i style={{color: "#1eee60", fontSize:"32px"}} className="bi bi-wifi">
 
  </i> Remote Website Support</h3>

<ul><i style={{color: "#1eee60", fontSize:"32px"}} className="bi bi-pencil-square">
  </i> Ongoing content creation & management<br></br>
<ul><i style={{fontSize:"17px"}} className="bi bi-check2">
  </i> Unlimited amount of edits & updates allowed with each web design plan.</ul>
</ul>        
<ul><i style={{color: "#1eee60", fontSize:"32px"}} className="bi bi-arrow-repeat">
 </i> Ongoing website technical support <br></br>
<ul><i style={{fontSize:"17px"}} className="bi bi-check2">
 </i> Contact us to get assistance with navigating website changes and resolve issues. </ul>
</ul>
</div>
</div>
</div>


<div className='column' >
<div className='box6'> 
<div className={box}>
<h3 style={{textAlign:"center", color: "#1eee60"}}>
<i style={{color: "#1eee60", fontSize:"32px"}} className="bi bi-house">
 </i> Personal Domain Name</h3>
<ul><i style={{color: "#1eee60", fontSize:"32px"}} className="bi bi-globe">
  </i>  Custom domain name <br></br> <ul><i style={{fontSize:"17px"}} className="bi bi-check2">
</i> Improve the presence and image of your brand with a custom web address.
</ul>
<ul><i style={{fontSize:"17px"}} className="bi bi-check2"></i> Secure <u>https://mybusinessname.com</u> for your website!
</ul>
</ul> 

<ul><i style={{color: "#1eee60", fontSize:"32px"}} className="bi bi-search"></i> Domain name added to search engines<br></br>  
<ul><i style={{fontSize:"17px"}} className="bi bi-check2">
</i> We add your web address to Google & Bing to increase your visibility online</ul> 
<ul><i style={{fontSize:"17px"}} className="bi bi-check2">
</i> Monitor your visibility and traffic through your Google/Bing account</ul>
</ul>

<br></br>
</div>
</div>

<br></br>

<div className='box6'>
<div className={box}>
  <h3 style={{textAlign:"center", color: "#1eee60"}}>
  <i style={{color: "#1eee60", fontSize:"32px"}} className="bi bi-person-bounding-box"></i> Reliable Host </h3>
<ul><i style={{color: "#1eee60", fontSize:"32px"}} className="bi bi-pc-display"></i> Database storage for content
<ul><i style={{fontSize:"17px"}} className="bi bi-check2"></i> Large amounts of storage for website data, photos, and other files. </ul>
</ul>
<ul><i style={{color: "#1eee60", fontSize:"32px"}} className="bi bi-database-lock"></i> Secure website hosting services 
<ul><i style={{fontSize:"17px"}} className="bi bi-check2"></i>
 Secure servers to protect vital data from website content data to uploaded/client input data. </ul>
</ul>
<br></br>
</div>
</div>

</div>


     </div>

     <div className='row'>



      <div className='column' >
        <div className='box6'>
        <div className={box}>
        <h3 style={{textAlign:"center", color: "#1eee60"}}>
          <i style={{color: "#1eee60", fontSize:"32px"}} className="bi bi-brush">
</i> Custom E-commerce Website</h3>
<ul><i style={{color: "#1eee60", fontSize:"32px"}} className="bi bi-cart-check"></i> Up to 300 product listings <br></br>
<ul><i style={{fontSize:"17px"}} className="bi bi-check2"></i> Create a number of listings with customized styles and features. </ul></ul>
<ul><i style={{color: "#1eee60", fontSize:"32px"}} className="bi bi-file-earmark-binary"></i> Up to 30 webpages allowed <br></br>
<ul><i style={{fontSize:"17px"}} className="bi bi-check2"></i> Various amounts of content, features and effects on each webpage. </ul></ul>
</div>
</div>

</div>

<div className='column' >
        <div className='box6'>
        <div className={box}>
        <h3 style={{textAlign:"center", color: "#1eee60"}}>
        <i style={{fontSize:"32px"}} className="bi bi-wallet2"></i> E-commerce Payment System</h3>
<ul><i  style={{color: "#1eee60", fontSize:"30px"}}  className="bi bi-paypal"></i> Web App with <a style={{fontSize:"18px"}} href="https://www.paypal.com/us/webapps/mpp/merchant-fees" target="_blank" rel="noreferrer">PayPal</a> payment integration <br></br>
<ul><i style={{fontSize:"17px"}} className="bi bi-check2"></i> Add a secure PayPal payment system to the web application to ensure the security of personal client information.</ul></ul>
<ul><i style={{color: "#1eee60", fontSize:"30px"}} className="bi bi-credit-card-2-back"></i> Wordpress website with PayPal / <a style={{fontSize:"18px"}} href="https://woocommerce.com/document/woopayments/fees-and-debits/fees" target="_blank" rel="noreferrer">WooCommerce integration</a> <br></br>
<ul><i style={{fontSize:"17px"}} className="bi bi-check2">
</i> Add either WooCommerce or PayPal to online shops and create a more simplified and effective checkout experience for buyers.</ul></ul>
</div>
</div>
</div>

</div>


<div className='intro'> <h3><a href="/pricing">View Pricing{">>>"}</a></h3> 
  </div>

    </div> 
    


   <br></br>
 
 </div> 

  );
}

export default Home;