import React,{ useContext, useMemo} from "react";
import {Link} from "react-router-dom";
import logo from "./Logo11.png";
import './home';
import "./about";
import "./pricing";
import "./apply";
import "./signup";
import "./profile";
import "./login";
import {AuthContext, ProfileContext} from "./App";
import {firebaseConfig} from "./App";
import  firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import data1 from "./App";


function Navigation () { 


  const auth = firebase.auth(firebaseConfig);
  const {authStatus}  = useContext(AuthContext);
  const {profileOpen} = useContext(ProfileContext);



    
      function profileLink (){
       if (authStatus && profileOpen){
        return(<div className="box7">&emsp;<Link to="/profile" onClick={()=>{window.scrollTo(0, 0)}}> 
         <i style={{color: "#1eee60", fontSize:"23px"}} className="bi bi-person-fill-check"></i>
       Logged In </Link> &emsp; </div>)}
  
       else {
        return(<div className="box7">&emsp; <a href="/login"> 
        <i style={{color: "#1eee60", fontSize:"20px"}} className="bi bi-person-fill-down"></i> Login </a>&emsp;&emsp; <a href="/signup"> <i style={{color: "#1eee60", fontSize:"20px"}} className="bi bi-person-fill-up"></i> Sign Up </a>&emsp; 
       </div>)}

       }
      
      
      



        return (
<div>

<nav className="navbar navbar-expand-lg">

<div className="container-fluid">
<div className="navbar-brand"><img style={{width:"130px"}} src={logo} alt="Night Tremors Designs Logo" /></div>


  <div className="navbar-collapse" id="navbarSupportedContent">
    <div className="navbar-nav mr-auto mb-2 mb-lg-0">  
    <a href="/" rel="canonical" >Home &emsp;</a>
<a href="/pricing" >Web Design Plans &emsp;</a>    
<Link to="/about" >Night Tremors &emsp;</Link>


    </div>
    </div>
    
{profileLink()}




</div>
    </nav>
  </div>
        );
    }

    export default Navigation;